export const products = [
    {
        id: "1",
        // title: "SELF DRIVEN CARS",
        // category: "category",
        imageSrc: "asset/rusta-copy.jpg",
        // price: "$23.00"
    },
    {
        id: "2",
        // title: "SCOOTER IN RENT",
        // category: "category",
        imageSrc: "asset/aa.jpg",
        // price: "$15.00"
    },
    {
        id: "3",
        // title: "AIRPORT TRANSFERS",
        // category: "category",
        imageSrc: "asset/bb.jpg",
        // price: "$10.25"
    },
    {
        id: "4",
        // title: "GOA TOURS",
        // category: "category",
        imageSrc: "asset/cc.jpg",
        // price: "$23.00"
    },
    {
        id: "5",
        // title: "CHAUFFUUR DRIVEN CARS",
        // category: "category",
        imageSrc: "asset/ee.jpg",
        // price: "$15.00"
    },
    {
        id: "6",
        // title: "COACHES",
        // category: "category",
        imageSrc: "asset/house1.jpg",
        // price: "$10.25"
    },
    {
        id: "6",
        // title: "COACHES",
        // category: "category",
        imageSrc: "asset/house2.jpg",
        // price: "$10.25"
    },
   
    {
        id: "6",
        // title: "COACHES",
        // category: "category",
        imageSrc: "asset/bathroom.jpg",
    
        // price: "$10.25"
    },
    {
        id: "6",
        // title: "COACHES",
        // category: "category",
        imageSrc: "asset/concert.jpg",
        // price: "$10.25"
    },
    // {
    //     id: "6",
    //     title: "COACHES",
    //     // category: "category",
    //     imageSrc: "asset/house3.jpg",
    //     // price: "$10.25"
    // },
];
